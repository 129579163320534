import React, { useEffect, useState } from "react";
import Dropdown from "../dropdown";
import { useNavigate } from "react-router";
import { getMenuList, getPath, toKebabCase } from "../../utils";
import { NavLink } from "react-router-dom";
import { isMobile } from "../../common/config";
import { useSelector } from "react-redux";
const Navbar = () => {
  const navigate = useNavigate();

  const masterData = useSelector(
    (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  );

  function handleChildNavigate(path, parent) {
    navigate(`${getPath(parent, { course: toKebabCase(path.title) })}`);
  }

  function handleMoreNavigate({ path }) {
    navigate(getPath(path));
  }

  const [menuList, setMenuList] = useState(getMenuList(masterData?.courses));
  const [moreMenu, setMoreMenu] = useState({
    parentLbl: "More",
    parentRoute: "",
    childItem: [],
  });

  const headerWrap = document.querySelector(".header__wrap")?.clientWidth;
  const headerMenu = document.querySelector(".header__menu")?.clientWidth;

  useEffect(() => {
    function checkMenuItems() {
      if (!isMobile() && headerMenu / headerWrap > 0.6 && menuList?.length) {
        const menu = menuList;
        const lastMenuItem = menu.pop();
        setMenuList(menu);
        setMoreMenu((state) => ({
          ...state,
          childItem: [
            ...state?.childItem,
            {
              ...lastMenuItem,
              title: lastMenuItem?.parentLbl,
            },
          ],
        }));
      }
    }
    checkMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuList]);

  return (
    <nav className="navbar">
      <ul>
        {!isMobile() ? (
          <>
            {menuList?.map((item, index) => (
              <li key={index}>
                {item.childItem ? (
                  <Dropdown
                    list={item.childItem}
                    value={{ title: item.parentLbl }}
                    handleClick={(path) =>
                      handleChildNavigate(path, item.parentRoute)
                    }
                    toggleOnHover
                  />
                ) : (
                  <NavLink to={getPath(item.path)}>
                    <span>{item.parentLbl}</span>
                  </NavLink>
                )}
              </li>
            ))}
            {moreMenu?.childItem?.length && (
              <li>
                <Dropdown
                  list={moreMenu?.childItem}
                  value={{ title: moreMenu.parentLbl }}
                  handleClick={(path) => handleMoreNavigate(path)}
                  toggleOnHover
                />
              </li>
            )}
          </>
        ) : (
          menuList?.map((item, index) => (
            <li key={index}>
              <NavLink to={getPath(item.path)}>
                <span>{item.parentLbl}</span>
                {item.childItem && (
                  <ul>
                    {item.childItem.map((child, index) => (
                      <li key={index}>
                        <NavLink
                          to={getPath(item.parentRoute, {
                            course: toKebabCase(child.title),
                          })}
                        >
                          <span>{child.title}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </NavLink>
            </li>
          ))
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
