import React from "react";
import Media from "../media";

const TestimonialCard = ({ cardConfig, title, caption, category }) => {
  return (
    <>
      <div className="testimonial__card">
        <div className="testimonial__card-thumb">
          <Media {...cardConfig} />
          <div className="testimonial__card-category">{category}</div>
        </div>
        <div className="testimonial__card-dtls">
          <h3 className="testimonial__card-title">{title}</h3>
          <h3
            className="testimonial__card-caption"
            dangerouslySetInnerHTML={{ __html: caption }}
          ></h3>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
