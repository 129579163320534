import React from "react";
import Media from "../media";
import Anchor from "../anchor";
import { IMAGE } from "../../common/config";

const ResourceCard = ({
  thumbnail,
  title,
  caption = false,
  isThumbnail,
  courseIconType,
  downloadLink,
}) => {
  return (
    <Anchor
      preventScrollReset={true}
      target="_self"
      rel="noopener noreferrer"
      linkCls="course__card"
      data-aos="zoom-in"
      hyperLink={downloadLink}
      extraAttri={{ download: !!downloadLink }}
      component={
        <>
          {!isThumbnail && (
            <figure className="course__card-thumb">
              <Media
                type={IMAGE}
                mediaURL={
                  thumbnail
                    ? thumbnail
                    : "default-placeholder/course-default1.jpg"
                }
                hasBaseURL={false}
              />
              {courseIconType && (
                <div className="course__card-icon">{courseIconType}a</div>
              )}
            </figure>
          )}
          <div className="course__card-body">
            {title && (
              <div className="course__card-row">
                <h2 className="course__card-title">{title}</h2>
              </div>
            )}
            {caption && (
              <div className="course__card-row">
                <h2 className="course__card-caption">{caption}</h2>
              </div>
            )}
          </div>
        </>
      }
    ></Anchor>
  );
};

export default ResourceCard;
