import React from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import Media from "../media";
import { IMAGE } from "../../common/config";

const EnpowerYourLearning = () => {
  const featuredArr = [
    "Feature USP 1",
    "Feature USP 2",
    "Feature USP 3",
    "Feature USP 4",
  ];
  return (
    <>
      <section className="section__row section--enpowerYourLearning">
        <div className="main__container">
          <div className="enpowerYourLearning__grid">
            <div
              className="enpowerYourLearning__grid-lhs"
              data-aos="fade-right"
            >
              <div className="section__contentBox">
                <SectionHeading
                  customCls={"section__heading"}
                  tagName={"h2"}
                  title={"The Ultimate Learning"}
                />
                <SectionCaption
                  caption={`<p>Empower Your Learning with Ednovate: Our innovative approach and personalized support ensure you grasp complex concepts effortlessly, setting you on the path to academic success and personal growth</p>`}
                />
              </div>
              <div className="enpowerYourLearning__list">
                <ul>
                  {featuredArr.map((item, index) => (
                    <li
                      key={index}
                      data-aos="fade-right"
                      data-aos-delay={100 * index}
                    >
                      <i className="edi-angle-double-right"></i>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="enpowerYourLearning__grid-rhs" data-aos="fade-left">
              <div className="enpowerYourLearning__thumb">
                <Media
                  type={IMAGE}
                  mediaURL={"enpowerYourLearningThumbnail.jpg"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EnpowerYourLearning;
