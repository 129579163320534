import React from "react";
import Media from "../media";
import { IMAGE } from "../../common/config";
import { CtaButton } from "../ctaButton";

const JoinTeamCard = ({ thumbnail, title, caption, clickAction }) => {
  return (
    <>
      <div className="joinTeam__card">
        <div className="joinTeam__card-thumb">
          <Media type={IMAGE} mediaURL={thumbnail} />
        </div>
        <div className="joinTeam__card-dtls">
          <h2 className="joinTeam__card-title">{title}</h2>
          <div className="joinTeam__card-caption">{caption}</div>
          <div className="joinTeam__card-cta">
            <CtaButton
              btnCls={"btn btn__tertiary"}
              btnText={"Apply Now"}
              iconCls={"edi-next"}
              onClickProp={clickAction}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinTeamCard;
