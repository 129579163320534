import React, { useEffect, useState } from "react";
import SectionHeading from "../sectionHeading";
import Dropdown from "../dropdown";
import { IMAGE, YOUTUBE_VIDEO } from "../../common/config";
import DemoLectureVideoPlayer from "../demoLectureVideoPlayer";
import { CtaButton } from "../ctaButton";
import { useSelector } from "react-redux";
import {
  useGetDemoLecturesQuery,
  useGetSectionHeadingsQuery,
} from "../../api/services/home";

// const demoLectureArr = [
//   {
//     id: 1,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/NDzslwhi3Mo?si=6Vmgpm0OAJ7aVwX5",
//     poster: "achiever-placeholder.jpg",
//     duration: "4:40",
//     title:
//       "CA Inter Nov'24 | Eklavya Batch | Advance Accounting | AS 13 | L2 | Prof. Gaurang Vyas",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
//   {
//     id: 2,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/jtHPfkr9bKU?si=p1ULaU5a1nMuou4O",
//     duration: "4:40",
//     title:
//       "CA Final | May/Nov 24 & 25 | SFM | Forex | L1 | By Prof. Dhawal Purohit",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
//   {
//     id: 3,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/NDzslwhi3Mo?si=6Vmgpm0OAJ7aVwX5",
//     duration: "4:40",
//     title:
//       "CA Inter Nov'24 | Eklavya Batch | Advance Accounting | AS 13 | L2 | Prof. Gaurang Vyas",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
//   {
//     id: 4,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/NDzslwhi3Mo?si=6Vmgpm0OAJ7aVwX5",
//     duration: "4:40",
//     title:
//       "CA Inter Nov'24 | Eklavya Batch | Advance Accounting | AS 13 | L2 | Prof. Gaurang Vyas",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
//   {
//     id: 5,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/NDzslwhi3Mo?si=6Vmgpm0OAJ7aVwX5",
//     duration: "4:40",
//     title:
//       "CA Inter Nov'24 | Eklavya Batch | Advance Accounting | AS 13 | L2 | Prof. Gaurang Vyas",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
//   {
//     id: 6,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/NDzslwhi3Mo?si=6Vmgpm0OAJ7aVwX5",
//     poster: "broken-image.png",
//     duration: "4:40",
//     title:
//       "CA Inter Nov'24 | Eklavya Batch | Advance Accounting | AS 13 | L2 | Prof. Gaurang Vyas",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
//   {
//     id: 7,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/NDzslwhi3Mo?si=6Vmgpm0OAJ7aVwX5",
//     duration: "4:40",
//     title:
//       "CA Inter Nov'24 | Eklavya Batch | Advance Accounting | AS 13 | L2 | Prof. Gaurang Vyas",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
//   {
//     id: 8,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/NDzslwhi3Mo?si=6Vmgpm0OAJ7aVwX5",
//     duration: "4:40",
//     title:
//       "CA Inter Nov'24 | Eklavya Batch | Advance Accounting | AS 13 | L2 | Prof. Gaurang Vyas",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
//   {
//     id: 9,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/NDzslwhi3Mo?si=6Vmgpm0OAJ7aVwX5",
//     duration: "4:40",
//     title:
//       "CA Inter Nov'24 | Eklavya Batch | Advance Accounting | AS 13 | L2 | Prof. Gaurang Vyas",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
//   {
//     id: 10,
//     type: "youtube",
//     url: "https://www.youtube.com/embed/NDzslwhi3Mo?si=6Vmgpm0OAJ7aVwX5",
//     duration: "4:40",
//     title:
//       "CA Inter Nov'24 | Eklavya Batch | Advance Accounting | AS 13 | L2 | Prof. Gaurang Vyas",
//     caption:
//       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio nulla facilis, atque tempora nobis vero sapiente maiores minus adipisci rerum, praesentium expedita accusamus velit natus. Repudiandae iure enim nihil at, blanditiis ab possimus obcaecati magnam alias voluptatem tenetur voluptates non ratione, ducimus unde laudantium! Ipsum, doloremque. Quod quia illum voluptatem!",
//     viewed: "10000",
//     uploadedDate: "17th Dec, 2023",
//   },
// ];

const DemoLectures = () => {
  const masterData = useSelector(
    (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  );

  const [selectedLecture, setSelectedLecture] = useState(undefined);
  const [selectedCourse, setSelectedCourse] = useState(
    masterData?.courses?.[0]
  );

  const { data } = useGetDemoLecturesQuery(selectedCourse?.id);
  const { data: sectionHeadings } = useGetSectionHeadingsQuery();

  useEffect(() => {
    setSelectedLecture(data?.[0]);
  }, [data]);

  return (
    <>
      <section
        className="section__row  section--demoLectures"
        data-aos="fade-up"
      >
        <div className="main__container">
          <div className="demoLecture__grid">
            <div className="demoLecture__grid-lhs">
              <div className="section__contentBox">
                <SectionHeading
                  customCls={"section__heading"}
                  tagName={"h2"}
                  title={sectionHeadings?.demo_lecture?.demo_lecture_title}
                />
              </div>
            </div>
            <div className="demoLecture__grid-rhs">
              <Dropdown
                customCls={"dropdown--grad"}
                list={masterData?.courses}
                value={selectedCourse}
                handleClick={(item) => {
                  setSelectedCourse(item);
                }}
              />
            </div>
          </div>
        </div>
        <div className="main__container">
          <div className="demoLecture__player">
            <div className="demoLecture__player-grid">
              <div className="demoLecture__player-lhs">
                {selectedLecture ? (
                  <DemoLectureVideoPlayer
                    videoConfig={
                      (selectedLecture, { mediaAttrib: { controls: true } })
                    }
                    type={YOUTUBE_VIDEO}
                    url={selectedLecture?.media_url}
                    duration={selectedLecture?.duration}
                    title={selectedLecture?.title}
                    caption={selectedLecture?.description}
                    viewed={selectedLecture?.viewed}
                    uploadedDate={selectedLecture?.uploadedDate}
                  />
                ) : (
                  "No Lecture"
                )}
              </div>
              <div className="demoLecture__player-rhs">
                <div className="demoLecture__player-list">
                  <ul>
                    {data?.map((item, index) => (
                      <li
                        style={{ animationDelay: "." + index + "s" }}
                        className={
                          selectedLecture?.id === item.id ? "active" : ""
                        }
                        key={index}
                      >
                        <CtaButton
                          btnCls={"btn__unset"}
                          onClickProp={() => setSelectedLecture(item)}
                          components={
                            <>
                              <DemoLectureVideoPlayer
                                videoConfig={{
                                  mediaURL: item?.media_url,
                                  hasBaseURL: false,
                                }}
                                type={IMAGE}
                                url={
                                  item?.thumbnail
                                    ? item?.thumbnail
                                    : "default-placeholder/video-default.png"
                                }
                                title={item?.title}
                                caption={item?.description}
                                uploadedDate={item?.uploadedDate}
                              />
                            </>
                          }
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DemoLectures;
