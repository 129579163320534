import React from "react";
import { IMAGE } from "../../common/config";
// import EnrolledStudents from "../enrolledStudents";
import Tag from "../tag";
import Media from "../media";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { getPath, toKebabCase } from "../../utils";
const CourseCard = ({
  id,
  thumbnail,
  title,
  caption = false,
  catergory,
  rating,
  proposerThumb,
  proposerName,
  publishDate,
  enrolledStudentThumb = [],
  enrolledCount,
  courseDiscountPrice,
  coursePrice,
  isThumbnail,
  courseIconType,
}) => {
  return (
    <>
      <Link
        to={getPath("coursesDetail", {
          id: id,
          course: toKebabCase(catergory),
        })}
        preventScrollReset={true}
        target="_self"
        rel="noopener noreferrer"
        className="course__card"
        data-aos="zoom-in"
      >
        {!isThumbnail && (
          <figure className="course__card-thumb">
            <Media
              type={IMAGE}
              mediaURL={
                thumbnail
                  ? thumbnail
                  : "default-placeholder/course-default1.jpg"
              }
              hasBaseURL={false}
              errorImage="default-placeholder/course-default1.jpg"
            />
            {courseIconType && (
              <div className="course__card-icon">{courseIconType}a</div>
            )}
          </figure>
        )}

        {(catergory || rating) && (
          <div className="course__card-head">
            <div className="course__card-row">
              {catergory && <Tag tagID={catergory} />}
              {rating && (
                <div className="course__card-rating">
                  <i className="edi-star-outline"></i>
                  <span>{rating}</span>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="course__card-body">
          {title && (
            <div className="course__card-row">
              <h2 className="course__card-title">{title}</h2>
            </div>
          )}
          {caption && (
            <div className="course__card-row">
              <h2 className="course__card-caption">{caption}</h2>
            </div>
          )}
          {(proposerName || publishDate) && (
            <div className="course__card-row">
              {proposerName && (
                <div className="course__card-proposer">
                  <figure>
                    {proposerThumb && (
                      <Media
                        type={IMAGE}
                        mediaURL={
                          proposerThumb ? proposerThumb : "user-placeholder.svg"
                        }
                        hasBaseURL={false}
                        errorImage="profile.png"
                      />
                    )}

                    <figcaption>{proposerName}</figcaption>
                  </figure>
                </div>
              )}

              <div className="course__card-date">
                <i className="edi-calendar"></i>
                <span>{publishDate || "Ongoing"}</span>
              </div>
            </div>
          )}
        </div>
        {(enrolledCount || coursePrice || courseDiscountPrice) && (
          <div className="course__card-foot">
            <div className="course__card-row">
              {/* {enrolledCount && (
                <EnrolledStudents
                  thumbArr={enrolledStudentThumb}
                  enrolledStats={enrolledCount}
                />
              )} */}
              {coursePrice && (
                <div className="course__card-price">
                  <div className="course__card-price">
                    <span className="discountPrice">
                      <i className="edi-rupee"></i>
                      {courseDiscountPrice}
                    </span>
                    <span
                      className={`realPrice ${
                        courseDiscountPrice ? "strikeOut" : ""
                      }`}
                    >
                      {coursePrice}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Link>
    </>
  );
};

export default CourseCard;
