import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../redux/actions";
import { Overlays } from "../overlays";
import { Swiper, SwiperSlide } from "swiper/react";
import Media from "../media";
import { VIDEO } from "../../common/config";

const OurCommunityOverlay = () => {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state?.app?.home);
  const communityData = useSelector(
    (state) => state?.homeApi?.queries?.["getCommunity(undefined)"]
  );

  return (
    <>
      {homeState?.showOurCommunityOverlay && (
        <Overlays
          overlayName={"community"}
          overlayConfig={{
            noOverlayHead: false,
            noOverlayFoot: false,
            onClickProp: () => {
              dispatch(home.setShowOurCommunityOverlay());
            },
          }}
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={16}
            // centeredSlides={true}
            grabCursor={true}
            className="mySwiper__overlayCommunity"
            onSwiper={(swiper) => {
              swiper.slideTo(
                communityData?.data?.findIndex(
                  (x) => x.id === homeState?.selectedOurCommunitySlide
                )
              );
            }}
          >
            {communityData?.data?.map((item) => (
              <SwiperSlide key={item?.id}>
                <div className="ourCommunity__overlay">
                  <div className="ourCommunity__overlay-thumbnail">
                    <Media
                      key={item?.id}
                      type={item?.type}
                      mediaAttrib={
                        item?.type === VIDEO
                          ? {
                              poster: item?.thumbnail,
                            }
                          : undefined
                      }
                      mediaURL={
                        item?.type === VIDEO ? item?.media_url : item?.thumbnail
                      }
                      hasBaseURL={false}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Overlays>
      )}
    </>
  );
};

export default OurCommunityOverlay;
