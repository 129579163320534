import React from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import { isMobile } from "../../common/config";
import ReviewCard from "./reviewCard";
import { useGetStudentFeedbackQuery } from "../../api/services/courses";

// const studentReviewArr = [
//   {
//     userThumbnail: "",
//     userName: "Sagar Vyas",
//     userSkill: "CA Intern",
//     userRating: "4.9",
//     userdescription: `Dorem ipsum dolor sit amet, consectetur
// adipiscing elit, sed do eiusmod tempor
// incididunt ut labore et dolore magna
// aliqua Quis ipsum.`,
//   },
//   {
//     userThumbnail: "",
//     userName: "Sagar Vyas",
//     userSkill: "CA Intern",
//     userRating: "4.9",
//     userdescription: `Dorem ipsum dolor sit amet, consectetur
// adipiscing elit, sed do eiusmod tempor
// incididunt ut labore et dolore magna
// aliqua Quis ipsum.`,
//   },
//   {
//     userThumbnail: "",
//     userName: "Sagar Vyas",
//     userSkill: "CA Intern",
//     userRating: "4.9",
//     userdescription: `Dorem ipsum dolor sit amet, consectetur
// adipiscing elit, sed do eiusmod tempor
// incididunt ut labore et dolore magna
// aliqua Quis ipsum.`,
//   },
// ];

const StudentReview = () => {
  const { data } = useGetStudentFeedbackQuery();
  return (
    <>
      <section className="section__row  section--studentReview">
        <div className="main__container" data-aos="fade-up">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={"What Our Students Saying"}
            />
            <SectionCaption
              caption={`<p>Through a combination of lectures, readings, discussions, students will gain a solid foundation in educational psychology.</p>`}
            />
          </div>
        </div>
        <div className="main__container">
          <div className="studentReview__swiper">
            <Swiper
              scrollbar={{
                hide: true,
              }}
              slidesPerView={"auto"}
              spaceBetween={isMobile() ? 16 : 30}
              modules={[Scrollbar]}
              className="mySwiper__studentReview"
            >
              {data?.map((item, index) => (
                <SwiperSlide key={index} data-aos="zoom-in">
                  <ReviewCard
                    userThumbnail={item.thumbnail}
                    userName={item.name}
                    userSkill={item.class}
                    userRating={item.rating}
                    userdescription={item.description}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentReview;
