import React, { useCallback, useEffect, useState } from "react";
import { Tabs } from "../tabs";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../redux/actions";
import { useCurrentRoute } from "../../hooks";
import { toKebabCase } from "../../utils";

const CourseType = ({ tabList }) => {
  const dispatch = useDispatch();
  const currentRoute = useCurrentRoute();
  const [data, setData] = useState(tabList);
  const homeState = useSelector((state) => state?.app?.home);

  const setActiveTab = useCallback(
    (tab) => {
      dispatch(home.setActiveCourseType(tab));
    },
    [dispatch]
  );

  useEffect(() => {
    if (tabList?.length) {
      setData(tabList);
      setActiveTab(
        currentRoute.id === "courses"
          ? tabList.find(
              (t) => toKebabCase(t.title) === currentRoute?.params?.course
            )?.id
          : tabList[0]?.id
      );
    }
  }, [currentRoute?.id, setActiveTab, currentRoute?.params?.course, tabList]);

  return (
    <>
      <div className="courseType__wrap">
        <Tabs
          customCls={"tabs__wrap--outline"}
          active={homeState?.activeCourseType}
          data={data}
          handleClick={(tab) => setActiveTab(tab)}
          type="object"
          displayKey="title"
        />
      </div>
    </>
  );
};

export default CourseType;
