import React from "react";
import { useGetBranchByStatesQuery } from "../../api/services/contactus";
import Map from "../map";

const EdnovateMap = () => {
  const { data: branches, isLoading: isBranchesLoading } =
    useGetBranchByStatesQuery();
  if (isBranchesLoading || !branches?.length) {
    return <></>;
  }
  return (
    <Map
      markers={branches?.map((branch) => ({
        position: [Number(branch?.latitude), Number(branch?.longitude)],
        address: branch?.address,
      }))}
    />
  );
};

export default EdnovateMap;
