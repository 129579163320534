import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { CtaButton } from "../ctaButton";
import { IMAGE, isMobile } from "../../common/config";
import Media from "../media";
import Anchor from "../anchor";
const CourseHerobanner = ({ data }) => {
  return (
    <>
      <section
        className="section__row  course__herobanner-wrap"
        data-aos="fade-up"
      >
        <div className="container__fluid">
          <div className="course__herobanner__swiper">
            <Swiper
              spaceBetween={isMobile() ? 8 : 40}
              slidesPerView={1.2}
              centeredSlides={true}
              // loop={true}
              // speed={1000}
              autoplay={{
                enabled: true,
                delay: 4000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              navigation={{
                prevEl: ".course__herobanner-swiper .swiper__nav-prev",
                nextEl: ".course__herobanner-swiper .swiper__nav-next",
              }}
              modules={[Autoplay, Navigation]}
              className="mySwiper__courseHerobanner"
            >
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="course__herobanner-thumb">
                    {item.url ? (
                      <>
                        <Anchor
                          hyperLink={item.url}
                          component={
                            <>
                              <Media type={IMAGE} mediaURL={item.imageName} />
                            </>
                          }
                        />
                      </>
                    ) : (
                      <>
                        <Media type={IMAGE} mediaURL={item.imageName} />
                      </>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper__nav">
              <CtaButton btnCls="swiper__nav-prev" iconCls={"edi-previous"} />
              <CtaButton btnCls="swiper__nav-next" iconCls={"edi-next"} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseHerobanner;
