import React from "react";
import SectionHeading from "../sectionHeading";
import Media from "../media";
import { IMAGE } from "../../common/config";
import { useGetAboutUsQuery } from "../../api/services/aboutus";

const MissionVision = () => {
  const { data } = useGetAboutUsQuery();

  const content = data?.[0];

  return (
    <>
      <section
        className="section__row section--missionVision"
        data-aos="fade-up"
      >
        <div className="main__container">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={content?.title}
            />
          </div>
        </div>
        <div className="main__container">
          <div className="misVis__grid">
            <div className="misVis__grid-lhs" data-aos="fade-right">
              <div className="misVis__card">
                <div className="misVis__card-thumb" data-aos="zoom-in">
                  <Media
                    type={IMAGE}
                    mediaURL={content?.mission_thumbnail}
                    hasBaseURL={false}
                  />
                </div>
                <div className="misVis__card-info">
                  <div className="misVis__card-title">
                    <h2>Our Mission</h2>
                  </div>
                  <div className="misVis__card-caption">
                    <p>{content?.mission}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="misVis__grid-rhs" data-aos="fade-left">
              <div className="misVis__card">
                <div className="misVis__card-thumb" data-aos="zoom-in">
                  <Media
                    type={IMAGE}
                    mediaURL={content?.vission_thumbnail}
                    hasBaseURL={false}
                  />
                </div>
                <div className="misVis__card-info">
                  <div className="misVis__card-title">
                    <h2>Our Vision</h2>
                  </div>
                  <div className="misVis__card-caption">
                    <p>{content?.vission}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MissionVision;
