import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import { IMAGE_VERSION, IMG_BASE } from "../../common/config";
import DiscoverNowButton from "../discoverNowButton";
import Media from "../media";
import { useRerenderAnimation } from "../../hooks";
import { CtaButton } from "../ctaButton";
const HeroBanner = ({ props, bannerConfig }) => {
  const { isCertificate } = bannerConfig;
  const { show: showSlide, handler: handleSlideChange } =
    useRerenderAnimation();

  return (
    <>
      <div className="heroBanner__swiper">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation={{
            prevEl: ".heroBanner__swiper .swiper__nav-prev",
            nextEl: ".heroBanner__swiper .swiper__nav-next",
          }}
          grabCursor={true}
          modules={[Navigation]}
          onSlideChange={handleSlideChange}
          className="mySwiper__heroBanner"
        >
          {props?.map((item) => (
            <SwiperSlide key={"hero-banner-" + item?.id}>
              {showSlide && (
                <div className="main__container--fluid">
                  <div className="heroBanner__grid">
                    {isCertificate && (
                      <div className="certifcate__stamp">
                        <img
                          src={`${IMG_BASE}certificate-round-stamp.svg?v=${IMAGE_VERSION}`}
                          alt="100 certificate guarantee stamp thumbnail"
                        />
                      </div>
                    )}
                    <div className="heroBanner__grid-lhs" data-aos="fade-right">
                      <div className="section__contentBox">
                        <SectionHeading
                          extraAttrib={{
                            "data-aos": "flip-up",
                            "data-aos-delay": 100,
                          }}
                          tagName="h2"
                          title={item?.title}
                        />
                        <SectionCaption
                          extraAttrib={{
                            "data-aos": "flip-up",
                            "data-aos-delay": 200,
                          }}
                          caption={item?.description}
                        />
                        <div
                          className="heroBanner__cta"
                          data-aos="flip-up"
                          data-aos-delay="300"
                        >
                          <DiscoverNowButton
                            btnText={item?.button_text}
                            onClickProp={() => {
                              window.location.href = item?.redirect_link;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="heroBanner__grid-rhs" data-aos="fade-left">
                      <div className="heroBanner__thumbnail">
                        <Media
                          type={item?.type}
                          mediaURL={
                            item?.type === "image"
                              ? item?.thumbnail
                              : item?.video_link
                          }
                          hasBaseURL={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper__nav">
          <CtaButton btnCls="swiper__nav-prev" iconCls={"edi-previous"} />
          <CtaButton btnCls="swiper__nav-next" iconCls={"edi-next"} />
        </div>
      </div>
    </>
  );
};

export default HeroBanner;
