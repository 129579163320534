import React from "react";

const SectionCaption = ({ customCls, caption, extraAttrib }) => {
  return (
    <>
      <div
        className={`section__caption ${customCls ? customCls : ""}`}
        {...extraAttrib}
        dangerouslySetInnerHTML={{ __html: caption }}
      ></div>
    </>
  );
};

export default SectionCaption;
