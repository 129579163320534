import React, { useState } from "react";
import SectionHeading from "../sectionHeading";
import Media from "../media";
import { IMAGE } from "../../common/config";
import { Overlays } from "../overlays";
import TestifyDetailCard from "../testifyDetailCard";
import { useGetLeadershipTeamQuery } from "../../api/services/aboutus";

// const leadershipTeamArr = [
//   {
//     thumbnail: "2-3-ratio-thumbnail.jpeg",
//     heading: "Our Team",
//     title: "John Smith",
//     description:
//       "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>",
//   },
//   {
//     thumbnail: "2-3-ratio-thumbnail.jpeg",
//     heading: "Our Team",
//     title: "John Smith",
//     description:
//       "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>",
//   },
//   {
//     thumbnail: "2-3-ratio-thumbnail.jpeg",
//     heading: "Our Team",
//     title: "John Smith",
//     description:
//       "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>",
//   },
//   {
//     thumbnail: "2-3-ratio-thumbnail.jpeg",
//     heading: "Our Team",
//     title: "John Smith",
//     description:
//       "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>",
//   },
//   {
//     thumbnail: "2-3-ratio-thumbnail.jpeg",
//     heading: "Our Team",
//     title: "John Smith",
//     description:
//       "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>",
//   },
//   {
//     thumbnail: "2-3-ratio-thumbnail.jpeg",
//     heading: "Our Team",
//     title: "John Smith",
//     description:
//       "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim veniam pariatur ex. Cum vitae at doloremque dolore quos dolorum. At!</p>",
//   },
// ];
const LeadershipTeam = () => {
  const { data } = useGetLeadershipTeamQuery();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  function toggleOverlay(index = 0) {
    setIsOverlayOpen((state) => !state);
    setActiveIndex(index);
  }

  console.log(data);

  return (
    <>
      <section className="section__row section--leadershipTeam">
        <div className="main__container">
          <div className="leadershipTeam__grid ">
            <div
              className="leadershipTeam__grid-item leadershipTeam__grid-item--heading"
              data-aos="fade-up"
            >
              <div className="section__contentBox">
                <SectionHeading
                  customCls={"section__heading"}
                  tagName={"h1"}
                  title={"Our Leadership Team"}
                />
              </div>
            </div>
            {data?.map((item, index) => (
              <div
                className="leadershipTeam__grid-item"
                key={index}
                data-aos="zoom-in"
                data-aos-delay={100 * index}
              >
                <div
                  className="leadershipTeam__thumb"
                  onClick={() => toggleOverlay(index)}
                >
                  <Media
                    type={IMAGE}
                    mediaURL={item?.thumbnail}
                    hasBaseURL={false}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {isOverlayOpen && (
        <Overlays
          overlayName={"testify"}
          overlayConfig={{
            noOverlayClose: true,
            noOverlayHead: false,
            noOverlayBody: true,
            noOverlayFoot: false,
            onClickProp: toggleOverlay,
          }}
        >
          <TestifyDetailCard
            title={data?.[activeIndex]?.designation}
            heading={data?.[activeIndex]?.lname}
            description="Description"
            thumbnail={data?.[activeIndex]?.thumbnail}
          />
        </Overlays>
      )}
    </>
  );
};

export default LeadershipTeam;
