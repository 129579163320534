import React from "react";
import Anchor from "../anchor";

const ContactBranchCard = ({
  branchName,
  branchAddress,
  branchMail,
  branchNumber,
  mapLink,
}) => {
  return (
    <>
      <div className="branchCard" data-aos="zoom-in">
        <div className="branchCard-head">
          <h2 className="branchCard-title">{branchName}</h2>
          <div className="branchCard-address">
            <i className="edi-location"></i>
            <address>{branchAddress}</address>
          </div>
        </div>

        <div className="branchCard-body">
          <Anchor
            linkCls={"btn btn__link"}
            iconCls={"edi-pinned"}
            linkText={"Locate Us"}
            hyperLink={mapLink}
          />
          <Anchor
            linkCls={"btn btn__link"}
            iconCls={"edi-mail"}
            linkText={"Mail Us"}
            hyperLink={`mailTo:${branchMail}`}
          />
          <Anchor
            linkCls={"btn btn__link"}
            iconCls={"edi-phone"}
            linkText={"Call Us"}
            hyperLink={`tel:${branchNumber}`}
          />
        </div>
      </div>
    </>
  );
};

export default ContactBranchCard;
