import React from "react";
import {
  IMAGE,
  IMAGE_VERSION,
  IMG_BASE,
  VIDEO,
  YOUTUBE_VIDEO,
} from "../../common/config";
import Video from "./video";

const Media = ({
  type,
  mediaURL,
  mediaAttrib,
  hasBaseURL = true,
  handleEnded = () => {},
  errorImage = "broken-image.png",
}) => {
  return (
    <>
      {type === IMAGE && (
        <>
          <img
            loading="lazy"
            src={`${
              mediaURL
                ? (hasBaseURL ? IMG_BASE : "") + mediaURL
                : IMG_BASE + errorImage
            }?v=${IMAGE_VERSION}`}
            alt={mediaURL}
            onError={(e) => {
              e.target.src = IMG_BASE + errorImage;
            }}
            {...mediaAttrib}
          />
        </>
      )}
      {type === VIDEO && (
        <>
          <Video
            mediaURL={mediaURL}
            mediaAttrib={mediaAttrib}
            hasBaseURL={hasBaseURL}
            handleEnded={handleEnded}
          />
        </>
      )}
      {type === YOUTUBE_VIDEO && (
        <>
          <iframe
            src={mediaURL}
            {...mediaAttrib}
            title="Ednovate YouTube video player"
          ></iframe>
        </>
      )}
    </>
  );
};

export default Media;
