import React from "react";
import Media from "../../media";
import Socials from "../../socials";
import { IMAGE, socialsList } from "../../../common/config";
import Anchor from "../../anchor";
import { NavLink } from "react-router-dom";
import { getMenuList, getPath, toKebabCase } from "../../../utils";
import { useSelector } from "react-redux";

const Sitemap = () => {
  const masterData = useSelector(
    (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  );

  const sitemapArr = [
    {
      title: "Site Link",
      childLinks: getMenuList(masterData?.courses)
        ?.filter((x) => !x?.childItem?.length)
        ?.map((data) => ({
          ...data,
          path: getPath(data?.path),
        })),
    },
    {
      title: "Courses",
      childLinks: masterData?.courses?.map((data) => ({
        ...data,
        parentLbl: data?.title,
        path: getPath("courses", {
          course: toKebabCase(data.title),
        }),
      })),
    },
    {
      title: "Get in Touch",
      childLinks: [
        { parentLbl: "Time Table", path: "/" },
        { parentLbl: "Careers", path: "/" },
        { parentLbl: "Business", path: "/" },
      ],
    },
    {
      title: "Contact with Us",
      customMarkup: [
        {
          iconName: "edi-pinned",
          linkURL: "https://goo.gl/maps/sWkSXaXMLEdUkxWc6",
          content: `<b>Head Office:</b> 4th Floor, Ajanta Square Mall, Near BMC Market, L.T Road, Borivali (W), Mumbai: 400092.`,
        },
        {
          iconName: "edi-phone",
          linkURL: "tel:+917277367367",
          content: `(+91) 72773 67367`,
        },
        {
          iconName: "edi-mail",
          linkURL: `mailto:info@ednovate.com`,
          content: `info@ednovate.com`,
        },
      ],
    },
  ];

  return (
    <>
      <div className="sitemap__wrap">
        <div className="main__container">
          <div className="sitemap__list">
            <div className="sitemap__grid">
              <div
                className="sitemap__grid-item"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <div className="sitemap__brandLogo">
                  <Media type={IMAGE} mediaURL={"ednovate-footer.svg"} />
                </div>
                <div className="sitemap__brandCaption">
                  <p>
                    Ednovate comprises of a team of dynamic CA teachers, who ha
                    ve been coaching students to their best scores at various
                    levels of CA over the years. These teachers have now come
                    together at Edno vate to create an institute that offers
                    students everything they need to crack these exams.
                  </p>
                </div>
                <div className="sitemap__socials">
                  <Socials
                    arrProps={socialsList.map((x) => ({
                      iconCls: x.iconName,
                      hyperLink: x.url,
                      linkCls: `btn btn__icon btn__round ${
                        x.iconExtraCls ? x.iconExtraCls : ""
                      }`,
                    }))}
                  />
                </div>
              </div>
              {sitemapArr.map((item, index) => (
                <div
                  key={index}
                  className="sitemap__grid-item"
                  data-aos="fade-right"
                  data-aos-delay={120 * (index + 1)}
                >
                  <h4 className="sitemap__heading">{item.title}</h4>
                  {item.childLinks && (
                    <div className="sitemap__links">
                      {item.childLinks?.map((childItem, childIndex) => (
                        <NavLink to={childItem.path} key={childIndex}>
                          <span>{childItem.parentLbl}</span>
                        </NavLink>
                      ))}
                    </div>
                  )}
                  {item.customMarkup?.map((item2, index) => (
                    <Anchor
                      key={index}
                      hyperLink={item2.linkURL}
                      component={
                        <>
                          <div className="sitemap__contactDtls">
                            <div className="sitemap__contactDtls-icon btn__icon">
                              <i className={item2.iconName}></i>
                            </div>
                            <div
                              className="sitemap__contactDtls-content"
                              dangerouslySetInnerHTML={{
                                __html: item2.content,
                              }}
                            ></div>
                          </div>
                        </>
                      }
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sitemap;
