import React, { useState } from "react";

const Dropdown = ({
  label,
  list,
  value,
  icon,
  handleClick,
  language,
  customCls,
  toggleOnHover = false,
}) => {
  const [isActive, setIsActive] = useState(true);
  return (
    <>
      <div
        className={`dropdown ${customCls ? customCls : ""} ${
          !isActive ? "active" : ""
        }`}
        onMouseEnter={() => {
          toggleOnHover && setIsActive(false);
        }}
        onMouseLeave={() => {
          toggleOnHover && setIsActive(true);
        }}
      >
        {label ? <div className="dropdown__lbl">{label}</div> : ""}
        <div className="dropdown__box">
          <div
            className="dropdown__value"
            onClick={() => {
              setIsActive(!isActive);
            }}
          >
            {icon ? <i className={icon}></i> : ""}
            <span dangerouslySetInnerHTML={{ __html: value?.title }}></span>

            {/* {value} */}
            <div className="value__icon">
              {!isActive ? (
                <i className="edi-chevron-up"></i>
              ) : (
                <i className="edi-chevron-down"></i>
              )}
            </div>
          </div>
          {!isActive ? (
            <div className="dropdown__list">
              <ul>
                {list?.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleClick(item);
                      setIsActive(!isActive);
                    }}
                    className={value?.id === item?.id ? "active" : ""}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: language ? language[item] : item?.title,
                      }}
                    ></span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
