import React from "react";
import Media from "../media";
import { getNumericNotation } from "../../common/config";

const DemoLectureVideoPlayer = ({
  type,
  videoConfig,
  url,
  duration,
  title,
  caption,
  viewed,
  uploadedDate,
}) => {
  return (
    <>
      <div className="demoLecture__playerBox">
        <div className="demoLecture__playerThumb">
          <Media {...videoConfig} mediaURL={url} type={type} />
          {duration && (
            <div className="demoLecture__playerDuration">{duration}</div>
          )}
        </div>
        <div className="demoLecture__playerDtls">
          <h2 className="demoLecture__playerTitle">{title}</h2>
          {caption && (
            <div
              className="demoLecture__playerCaption"
              dangerouslySetInnerHTML={{ __html: caption }}
            ></div>
          )}
          {false && (
            <div className="demoLecture__playerWidget">
              {viewed && <span>{getNumericNotation(viewed)} viewed</span>}
              {uploadedDate && <span>{uploadedDate}</span>}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DemoLectureVideoPlayer;
