import React from "react";
import SectionHeading from "../sectionHeading";
import AchieverCard from "../achieverCard";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Navigation } from "swiper/modules";
import { CtaButton } from "../ctaButton";
import {
  useGetAchieversQuery,
  useGetSectionHeadingsQuery,
} from "../../api/services/home";
const OurAchievers = () => {
  // const OurAchieversArr = [
  //   {
  //     achieverThumbnail: "",
  //     achieverName: "Sonaxi Parekh",
  //     achieverCaption: `Begin your educational voyage towards a prosperous career. Explore a variety
  //     of pecialized courses designed to empower your aspirations. Discover
  //     avenues to excel and embark on a path of lifelong learning.`,
  //     achieverKeyword: [
  //       "Accomplishments",
  //       "Community Involvement",
  //       "Extracurricular Excellence",
  //       "Great Result",
  //     ],
  //   },
  //   {
  //     achieverThumbnail: "",
  //     achieverName: "Ashish Shah",
  //     achieverCaption: `Begin your educational voyage towards a prosperous career. Explore a variety
  //     of pecialized courses designed to empower your aspirations. Discover
  //     avenues to excel and embark on a path of lifelong learning.`,
  //     achieverKeyword: [
  //       "Accomplishments",
  //       "Community Involvement",
  //       "Extracurricular Excellence",
  //     ],
  //   },
  // ];

  const { data } = useGetAchieversQuery();
  const { data: sectionHeadings } = useGetSectionHeadingsQuery();

  return (
    <>
      <section
        className="section__row  section--ourAchievers"
        data-aos="fade-up"
      >
        <div className="main__container">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={sectionHeadings?.achiever?.achiever_title}
            />
          </div>
        </div>
        <div className="main__container">
          <div className="achieverCard__swiper">
            <Swiper
              spaceBetween={16}
              navigation={{
                prevEl: ".achieverCard__swiper .swiper__nav-prev",
                nextEl: ".achieverCard__swiper .swiper__nav-next",
              }}
              modules={[Navigation]}
              className="mySwiper__ourAchievers"
            >
              {data?.map((item, index) => (
                <SwiperSlide key={index}>
                  <AchieverCard {...item} />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper__nav">
              <CtaButton btnCls="swiper__nav-prev" iconCls={"edi-previous"} />
              <CtaButton btnCls="swiper__nav-next" iconCls={"edi-next"} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurAchievers;
