import React from "react";
import { VIDEO, isMobile } from "../../common/config";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import Media from "../media";
import { useDispatch } from "react-redux";
import { home } from "../../redux/actions";
import { useGetCommunityQuery } from "../../api/services/home";

// const ourCommunityArr = [
//   [{ id: 1, type: "image", mediaURL: "achiever-placeholder.jpg" }],
//   [
//     {
//       id: 2,
//       type: "video",
//       mediaURL: "about_us_banner.mp4",
//       mediaAttrib: {
//         poster: `${IMG_BASE}${"achiever-placeholder.jpg"}?v=${IMAGE_VERSION}`,
//       },
//     },
//     { id: 3, type: "image", mediaURL: "achiever-placeholder.jpg" },
//   ],
//   [{ id: 4, type: "image", mediaURL: "achiever-placeholder.jpg" }],
//   [
//     { id: 5, type: "image", mediaURL: "achiever-placeholder.jpg" },
//     { id: 6, type: "image", mediaURL: "achiever-placeholder.jpg" },
//   ],
//   [{ id: 7, type: "image", mediaURL: "achiever-placeholder.jpg" }],
//   [
//     { id: 8, type: "image", mediaURL: "achiever-placeholder.jpg" },
//     { id: 9, type: "image", mediaURL: "achiever-placeholder.jpg" },
//   ],
//   [
//     {
//       id: 10,
//       type: "video",
//       mediaURL: "about_us_banner.mp4",
//       mediaAttrib: {
//         poster: `${IMG_BASE}${"achiever-placeholder.jpg"}?v=${IMAGE_VERSION}`,
//       },
//     },
//   ],
//   [
//     {
//       id: 11,
//       type: "video",
//       mediaURL: "about_us_banner.mp4",
//       mediaAttrib: {
//         poster: `${IMG_BASE}${"achiever-placeholder.jpg"}?v=${IMAGE_VERSION}`,
//       },
//     },
//     {
//       id: 12,
//       type: "video",
//       mediaURL: "about_us_banner.mp4",
//       mediaAttrib: {
//         poster: `${IMG_BASE}${"achiever-placeholder.jpg"}?v=${IMAGE_VERSION}`,
//       },
//     },
//   ],
// ];

const OurCommunitySwiper = () => {
  const dispatch = useDispatch();
  const { data } = useGetCommunityQuery();

  function formatCommunityData(item, index) {
    if (index === 0 || index % 3 === 0) {
      return [item];
    } else if (index !== 0 && (index - 1) % 3 === 0 && data?.[index + 1]) {
      return [data?.[index], data?.[index + 1]];
    } else if (index !== 0 && (index - 1) % 3 === 0) {
      return [data?.[index]];
    } else {
      return undefined;
    }
  }

  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={isMobile() ? 16 : 40}
        // centeredSlides={true}
        grabCursor={true}
        speed={1000}
        autoplay={{
          enabled: true,
          delay: 2500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper_ourCommunity"
      >
        {data?.map(formatCommunityData)?.map((item, index) => (
          <SwiperSlide key={index}>
            {item?.map((childData, index) => (
              <div
                key={index}
                className="ourCommunity__thumb"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(home.setShowOurCommunityOverlay(childData.id));
                }}
              >
                <Media
                  type={childData?.type}
                  mediaAttrib={
                    childData?.type === VIDEO
                      ? {
                          poster: childData?.thumbnail,
                        }
                      : undefined
                  }
                  mediaURL={
                    childData?.type === VIDEO
                      ? childData?.media_url
                      : childData?.thumbnail
                  }
                  hasBaseURL={false}
                />
              </div>
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default OurCommunitySwiper;
