import React from "react";
import Dropdown from "../../dropdown";
import InputLabel from "../inputLbl";

const InputDropdown = ({
  ddLbl,
  ddId,
  ddList,
  ddTooltipIcon,
  ddTooltipText,
  dropdownValue,
  setdropdownValue,
  dropdownConfig,
}) => {
  return (
    <>
      {ddLbl ? (
        <InputLabel
          inputLbl={ddLbl}
          inputID={ddId}
          inputTooltipIcon={ddTooltipIcon}
          inputTooltipText={ddTooltipText}
        />
      ) : (
        ""
      )}
      <Dropdown
        list={ddList}
        value={dropdownValue}
        handleClick={(value) => setdropdownValue(value)}
        {...dropdownConfig}
      />
    </>
  );
};

export default InputDropdown;
