import React, { useState } from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import TopRankerCard from "../topRankerCard";
import { CtaButton } from "../ctaButton";
import { Overlays } from "../overlays";
import TestifyDetailCard from "../testifyDetailCard";

const TopRankerListWithCategory = ({ data }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  function toggleOverlay(index = 0) {
    setIsOverlayOpen((state) => !state);
    setActiveIndex(index);
  }
  return (
    <>
      <section className="section__row  section--topRankersHex">
        <div className="main__container ">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={"Our Top Rankers"}
            />
            <SectionCaption
              caption={`<p>Begin your educational voyage towards a prosperous career. Explore a variety of specialized courses designed to empower your aspirations. Discover avenues to excel and embark on a path of lifelong learning.</p>`}
            />
          </div>
        </div>
        <div className="main__container">
          <div className="topRanker__list">
            {data?.map((item, index) => (
              <CtaButton
                key={item?.id}
                onClickProp={() => {
                  toggleOverlay(index);
                }}
                components={
                  <>
                    <TopRankerCard
                      key={item?.id}
                      isHex={true}
                      id={item?.id}
                      thumb={item.thumbnail}
                      name={item.name}
                      rank={item.rank}
                    />
                  </>
                }
              />
            ))}
          </div>
          <div className="topRanker__cta">
            <CtaButton
              btnCls={"btn btn__primary"}
              iconCls={"edi-next"}
              btnText={"View All"}
              onClickProp={() => {}}
            />
          </div>
        </div>
      </section>

      {isOverlayOpen && (
        <Overlays
          overlayName={"testify"}
          overlayConfig={{
            noOverlayClose: true,
            noOverlayHead: false,
            noOverlayBody: true,
            noOverlayFoot: false,
            onClickProp: toggleOverlay,
          }}
        >
          <TestifyDetailCard
            heading="Testimonial"
            title={data?.[activeIndex]?.name}
            description="Description"
            thumbnail={data?.[activeIndex]?.thumbnail}
          />
        </Overlays>
      )}
    </>
  );
};

export default TopRankerListWithCategory;
