import React from "react";
import Media from "../media";
import { IMAGE, isMobile } from "../../common/config";
import SectionHeading from "../sectionHeading";

const TestifyDetailCard = ({ heading, thumbnail, title, description }) => {
  return (
    <>
      <div className="testifyDetailCard__box">
        {isMobile() && heading && (
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={heading}
            />
          </div>
        )}
        <div className="testifyDetailCard__grid">
          <div className="testifyDetailCard__grid-lhs" data-aos="fade-right">
            <div className="testifyDetailCard__thumb">
              <Media type={IMAGE} mediaURL={thumbnail} hasBaseURL={false} />
            </div>
          </div>
          <div className="testifyDetailCard__grid-rhs" data-aos="fade-left">
            {!isMobile() && heading && (
              <div className="section__contentBox">
                <SectionHeading
                  customCls={"section__heading"}
                  tagName={"h2"}
                  title={heading}
                />
              </div>
            )}
            <div className="testifyDetailCard__icon">
              <i className="edi-quotes"></i>
            </div>
            {title && <h3 className="testifyDetailCard__title">{title}</h3>}
            {description && (
              <div
                className="testifyDetailCard__caption"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestifyDetailCard;
