import React, { useRef, useState } from "react";
import { CtaButton } from "../../ctaButton";
import { IMAGE_VERSION, IMG_BASE } from "../../../common/config";

const Video = ({
  mediaURL,
  mediaAttrib,
  hasBaseURL,
  handleEnded = () => {},
}) => {
  const [isStopped, setIsStopped] = useState(true);
  const videoRef = useRef(null);

  return (
    <div className="videoPlayer">
      {isStopped && (
        <div className="videoPlayer__cta">
          <CtaButton
            btnCls={"btn btn__icon btn__round btn__playPause"}
            iconCls={`${isStopped ? "edi-play" : ""}`}
            onClickProp={() => {
              console.log("CALLED");
              setIsStopped(false);
              videoRef.current.play();
            }}
          />
        </div>
      )}
      <video
        {...mediaAttrib}
        controls={!isStopped}
        ref={videoRef}
        onPlaying={() => {
          setIsStopped(false);
        }}
        onPause={() => {
          setIsStopped(true);
        }}
        onEnded={handleEnded}
      >
        <source
          src={`${
            mediaURL
              ? (hasBaseURL ? IMG_BASE : "") + mediaURL
              : "broken-image.png"
          }?v=${IMAGE_VERSION}`}
          type="video/mp4"
        />
        <source
          src={`${
            mediaURL
              ? (hasBaseURL ? IMG_BASE : "") + mediaURL
              : "broken-image.png"
          }?v=${IMAGE_VERSION}`}
          type="video/ogg"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
