import React from "react";

const SectionHeading = ({ customCls, tagName, title, extraAttrib }) => {
  const CustomTag = tagName;
  return (
    <>
      <CustomTag
        className={` ${customCls ? customCls : "section__title"}`}
        {...extraAttrib}
        dangerouslySetInnerHTML={{ __html: title }}
      ></CustomTag>
    </>
  );
};

export default SectionHeading;
