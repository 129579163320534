import React, { useState } from "react";
import { learningTypeArr } from "../../common/config";
import { Overlays } from "../overlays";
import Accordion from "../accordion";
import AccordionChild from "../accordion/accordionChild";
import InputCheckbox from "../forms/inputCheckbox";

const FilterOverlay = ({ handleApply = () => {} }) => {
  const [activeIndex, setActiveIndex] = useState([0]);

  function updateActiveIndex(index) {
    setActiveIndex((val) => {
      if (val.includes(index)) {
        val.splice(
          val.findIndex((x) => x === index),
          1
        );
        return [...val];
      } else {
        return [...val, index].sort();
      }
    });
  }

  return (
    <>
      <Overlays
        overlayName={"filter"}
        overlayConfig={{
          title: "Filter",
          cancelBtn: true,
          submitBtn: true,
          btnPrimary: "Apply",
          btnPrimaryIcon: "edi-next",
          btnSecondary: "Reset",
          btnSecondaryIcon: "edi-loadmore",
          noOverlayHead: true,
          noOverlayFoot: true,
          onClickProp: handleApply,
          onConfirmClick: handleApply,
          onCancelClick: () => {},
        }}
      >
        <Accordion
          data={learningTypeArr}
          activeIndex={activeIndex}
          accordionChild={learningTypeArr.map((item, index) => (
            <AccordionChild
              index={index}
              isActive={activeIndex.includes(index)}
              key={index}
              updateActiveIndex={updateActiveIndex}
              accordionHead={
                <>
                  <h2 className="accordion__header">{item}</h2>
                </>
              }
              bodyComponent={
                <>
                  <InputCheckbox
                    checkboxID={item}
                    label={item}
                    onChange={() => {}}
                  />
                </>
              }
              accordionConfig={{}}
            />
          ))}
        />
      </Overlays>
    </>
  );
};

export default FilterOverlay;
