import React, { useEffect, useState } from "react";
import { InputField } from "../forms/inputField";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import { Textarea } from "../forms/textareaField";
import ValidationText from "../forms/validationText";
import InputUpload from "../forms/inputUpload";
import { CtaButton } from "../ctaButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useAddCareerMutation } from "../../api/services/workWithUs";
import { MOBILE_REGEX } from "../../utils";

const schema = yup
  .object({
    firstName: yup.string().required().max(50),
    lastName: yup.string().required().max(50),
    location: yup.string().required().max(50),
    emailID: yup.string().email().required(),
    phoneNumber: yup
      .string()
      .matches(MOBILE_REGEX, "Invalid Mobile number")
      .required(),
    additionalInformation: yup.string(),
    educationQualification: yup.string().required().max(150),
    profileLooking: yup.string().required().max(150),
    resumeCareer: yup
      .mixed()
      .test("fileSize", "The file is too large", (value) => {
        if (!value.length) return true; // attachment is optional
        return value[0].size <= 2000000;
      }),
  })
  .required();

const uploadClientLogoConfig = {
  uploadFileLbl: "Upload Document",
  uploadFileID: "resumeCareer",
  uploadFileAccept:
    ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  uploadLblText: "Drag file to upload or Browse File",
  uploadFileName: "",
  components: false && <ValidationText validationText={""} />,
  uploadNotes: ["Max file size: 400KB", "Supported file types: PDF,DOCX,DOC"],
};
const CareerForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    // getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [submitCareer, { isLoading, isSuccess }] = useAddCareerMutation();
  const [formSuccess, setFormSuccess] = useState(false);

  const onSubmit = (data) => {
    let payload = new FormData();
    payload.append("name", data.firstName);
    payload.append("last_name", data.lastName);
    payload.append("email_id", data.emailID);
    payload.append("mobile_no", data.phoneNumber);
    payload.append("message", data.additionalInformation);
    payload.append("firm_name", data.firmName);
    payload.append("location", data.location);
    payload.append("apply_for", data.profileLooking);
    payload.append("education", data.educationQualification);
    payload.append("document", null); // TODO: File upload
    submitCareer(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      setFormSuccess(true);
      setTimeout(() => {
        setFormSuccess(false);
      }, 2500);
    }
  }, [isSuccess, reset]);

  return (
    <>
      <section className="section__row  section--joinTeamForm">
        <div className="main__container">
          <div className="joinTeam__form">
            <div className="section__contentBox">
              <SectionHeading
                customCls={"section__heading"}
                tagName={"h2"}
                title={"Career"}
              />
              <SectionCaption
                caption={`<p>Begin your educational voyage towards a prosperous career. Explore a variety of pecialized courses designed to empower your aspirations. Discover avenues to excel and embark on a path of lifelong learning.</p>`}
              />
            </div>

            <form className="form__wrap" onSubmit={handleSubmit(onSubmit)}>
              <div className="form__box">
                <div className="form__grid">
                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      inputLbl={"First Name"}
                      inputID={"firstName"}
                      components={
                        <ValidationText
                          validationText={errors?.firstName?.message}
                        />
                      }
                    />
                  </div>
                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      inputLbl={"Last Name"}
                      inputID={"lastName"}
                      components={
                        <ValidationText
                          validationText={errors?.lastName?.message}
                        />
                      }
                    />
                  </div>
                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      inputLbl={"Email Address"}
                      inputID={"emailID"}
                      inputType={"text"}
                      handleInput={() => {}}
                      onBlur={() => {}}
                      onFocus={() => {}}
                      extraAttri={{
                        inputMode: "email",
                        autoCapitalize: "off",
                      }}
                      components={
                        <ValidationText
                          validationText={errors?.emailID?.message}
                        />
                      }
                    />
                  </div>
                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      inputLbl={"Contact Number"}
                      inputID={"phoneNumber"}
                      inputType={"tel"}
                      handleInput={() => {}}
                      onBlur={() => {}}
                      onFocus={() => {}}
                      extraAttri={{
                        maxLength: "12",
                        inputMode: "numeric",
                      }}
                      components={
                        <ValidationText
                          validationText={errors?.phoneNumber?.message}
                        />
                      }
                    />
                  </div>
                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      inputLbl={"Location"}
                      inputID={"location"}
                      components={
                        <ValidationText
                          validationText={errors?.location?.message}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form__box">
                <div className="form__grid">
                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      inputLbl={"Education Qualification"}
                      inputID={"educationQualification"}
                      components={
                        <ValidationText
                          validationText={
                            errors?.educationQualification?.message
                          }
                        />
                      }
                    />
                  </div>

                  <div className="form__grid-item">
                    <InputField
                      registerFunction={register}
                      inputLbl={"Profile You Are Looking For"}
                      inputID={"profileLooking"}
                      components={
                        <ValidationText
                          validationText={errors?.profileLooking?.message}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form__box">
                <div className="form__grid">
                  <div className="form__grid-item form__grid-item--fullWidth">
                    <Textarea
                      registerFunction={register}
                      inputLbl={"Tell us about yourself"}
                      inputID={"additionalInformation"}
                      handleInput={() => {}}
                      onBlur={() => {}}
                      onFocus={() => {}}
                      components={
                        <ValidationText
                          validationText={
                            errors?.additionalInformation?.message
                          }
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form__box">
                <div className="form__grid">
                  <div className="form__grid-item form__grid-item--fullWidth">
                    <InputUpload
                      {...uploadClientLogoConfig}
                      handleCoverImageChange={() => {}}
                      handleRemoveImage={() => {}}
                      registerFunction={register}
                      inputValidate={true ? true : false}
                      components={
                        <ValidationText
                          validationText={errors?.resumeCareer?.message}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form__cta">
                <CtaButton
                  btnCls={"btn btn__primary"}
                  btnText={"Submit"}
                  iconCls={"edi-next"}
                  btnType="submit"
                  btnState={isLoading}
                  onClickProp={() => {}}
                />
              </div>
            </form>
            {formSuccess && "Submitted successfully"}
          </div>
        </div>
      </section>
    </>
  );
};

export default CareerForm;
