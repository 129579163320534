import React from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import Media from "../media";
import { IMAGE } from "../../common/config";
import { CtaButton } from "../ctaButton";
import { useGetFreeResourcesQuery } from "../../api/services/courses";
import { useNavigate } from "react-router";
import { getPath } from "../../utils";
import ResourceCard from "../resourceCard";

// const freeResourceCardArr = [
//   {
//     thumbnail: "",
//     title: "Taxation - IDT",
//     caption: "Begin your educational voyage towards a variety of pecialized.",
//   },
//   {
//     thumbnail: "",
//     title: "A Comprehensive Overview of The Field of Education.",
//     caption: "Begin your educational voyage towards a variety of pecialized.",
//   },
//   {
//     thumbnail: "",
//     title: "Corporate & Other Laws",
//     caption: "Begin your educational voyage towards a variety of pecialized.",
//   },
//   {
//     thumbnail: "",
//     title: "Taxation - IDT",
//     caption: "Begin your educational voyage towards a variety of pecialized.",
//   },
// ];
const FreeResourceSection = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetFreeResourcesQuery();
  const MAX_ITEMS = 3;

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <section className="section__row section--freeResourceSection">
        <div className="main__container">
          <div className="section__contentBox">
            <div className="section__thumb">
              <Media type={IMAGE} mediaURL={"icons/callout.svg"} />
            </div>
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={"Buy Start Learning with Free Resources"}
            />
            <SectionCaption
              caption={`<p>Begin your educational voyage towards a prosperous career. Explore a variety of pecialized courses designed to empower your aspirations. Discover avenues to excel and embark on a path of lifelong learning.</p>`}
            />
          </div>
        </div>
        <div className="main__container">
          <div className="freeResource__list">
            {data?.slice(0, MAX_ITEMS)?.map((item) => (
              <ResourceCard
                key={item?.id}
                thumbnail={item.thumbnail}
                title={item.title}
                caption={item.description}
                downloadLink={item?.download_link}
              />
            ))}
          </div>
        </div>
        <div className="main__container">
          <div className="freeResource__cta">
            <CtaButton
              btnCls={"btn btn__primary"}
              iconCls={"edi-next"}
              btnText={"View All"}
              onClickProp={() => {
                navigate(getPath("freeResources"));
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default FreeResourceSection;
