import React, { useState } from "react";
import { Tabs } from "../tabs";

const SubjectType = ({ tabList }) => {
  const [data] = useState(tabList);

  return (
    <>
      <div className="SubjectType__wrap">
        <Tabs
          customCls={"tabs__wrap--tertiary"}
          active={data[0]}
          data={data}
          handleClick={() => {}}
          type="text"
        />
      </div>
    </>
  );
};

export default SubjectType;
