import React, { useState } from "react";
import SectionHeading from "../sectionHeading";
import SectionCaption from "../sectionCaption";
import LearningType from "../learningType";
import CourseType from "../courseType";
import { F2F_LEARNING_TYPE_ID, sortArr } from "../../common/config";
import { CtaButton } from "../ctaButton";
import CourseCard from "../courseCard";
import Dropdown from "../dropdown";
import InputSearchBox from "../forms/inputSearchBox";
import FilterOverlay from "../filterOverlay";
import { useSelector } from "react-redux";
import { useGetPopularCoursesQuery } from "../../api/services/home";
import { useGetBranchByStatesQuery } from "../../api/services/contactus";
import BranchCard from "../branchCard";

const onlineCourseRowArr = [
  {
    title: "By Groups",
    hasFilter: false,
  },
  {
    title: "By Subjects",
    hasFilter: false,
  },
  {
    title: "By Chapters",
    hasFilter: true,
  },
];

// const courseCardArr = [
//   {
//     thumbnail: "default-placeholder/course-default2.jpg",
//     title: "Business and Commercial Knowledge ",
//     catergory: "SYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Khyati Shah",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "15000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "default-placeholder/course-default2.jpg",
//     title: "Business and Commercial Knowledge ",
//     catergory: "SYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Khyati Shah",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "15000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "default-placeholder/course-default2.jpg",
//     title: "Business and Commercial Knowledge ",
//     catergory: "SYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Khyati Shah",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "15000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "default-placeholder/course-default2.jpg",
//     title: "Business and Commercial Knowledge ",
//     catergory: "SYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Khyati Shah",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "15000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "default-placeholder/course-default3.jpg",
//     title: "Business and Commercial Knowledge ",
//     catergory: "CA Inter",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Khyati Shah",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "1000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "default-placeholder/course-default3.jpg",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "CA Inter",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledStudentThumb: ["profile.png", "profile.png", "profile.png"],
//     enrolledCount: "10",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "default-placeholder/course-default3.jpg",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "CA Foundation",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "100",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "default-placeholder/course-default3.jpg",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "CA Inter",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledStudentThumb: ["profile.png", "profile.png", "profile.png"],
//     enrolledCount: "10",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "default-placeholder/course-default3.jpg",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "CA Inter",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledStudentThumb: ["profile.png", "profile.png", "profile.png"],
//     enrolledCount: "10",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "CA Foundation",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "200000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "CA Foundation",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "200000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "CA Foundation",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "200000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "FYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "2000000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "FYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "2000000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "FYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "2000000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     thumbnail: "",
//     title:
//       "Complete Guide for CA Foundation Complete Guide for CA Foundation Foundation",
//     catergory: "FYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Sagar Vyas",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "2000000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
// ];

const OnlineCourses = () => {
  const [isFilterOverlayOpen, setIsFilterOverlayOpen] = useState();
  function toggleFilterOverlay() {
    setIsFilterOverlayOpen((value) => !value);
  }

  const [maxItems, setMaxItems] = useState(6);
  function loadMore() {
    setMaxItems((val) => val + 6);
  }

  const masterData = useSelector(
    (state) => state?.masterApi?.queries?.["getMasterData(undefined)"]?.data
  );
  const homeState = useSelector((state) => state?.app?.home);

  const { data: popularCourses } = useGetPopularCoursesQuery({
    courseID: homeState?.activeCourseType,
    learningMode: homeState?.activeLearningType,
  });

  const { data: branches } = useGetBranchByStatesQuery("");

  return (
    <>
      <section
        className="section__row  section--availableCourses"
        data-aos="zoom-out"
      >
        <div className="main__container ">
          <div className="section__contentBox">
            <SectionHeading
              customCls={"section__heading"}
              tagName={"h2"}
              title={"Buy Courses"}
            />
            <SectionCaption
              caption={`<p>Begin your educational voyage towards a prosperous career. Explore a variety of pecialized courses designed to empower your aspirations. Discover avenues to excel and embark on a path of lifelong learning.</p>`}
            />
          </div>
        </div>
        <div className="main__container">
          <LearningType tabList={masterData?.learning_modes} />
        </div>
        <div className="main__container">
          <CourseType tabList={masterData?.courses} />
        </div>
      </section>
      <div className="onlineCourses__wrap">
        {Number(homeState?.activeLearningType) === F2F_LEARNING_TYPE_ID ? (
          <>
            <div className="onlineCourses__row" data-aos="fade-up">
              <div className="main__container">
                <h3 className="onlineCourses__heading">Offline Batches</h3>
              </div>
            </div>

            <div className="course__wrap">
              <div className="main__container">
                <div className="onlineCourses__grid">
                  {branches?.map((item, index) => (
                    <BranchCard
                      branchName={item?.city}
                      branchAddress={item?.address}
                      mapLink={item?.map_link}
                      branchNumber={item?.mobile_no}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          onlineCourseRowArr.map((item, index) => (
            <div className="onlineCourses__row" key={index} data-aos="fade-up">
              <div className="main__container">
                <h3 className="onlineCourses__heading">{item.title}</h3>
                <div className="filters__wrap">
                  <div className="filters__grid">
                    <div className="filters__grid-lhs">
                      {item?.hasFilter && (
                        <CtaButton
                          btnCls={"btn btn__primary btn__round"}
                          btnText={"Filter"}
                          iconCls={"edi-filter"}
                          onClickProp={toggleFilterOverlay}
                        />
                      )}
                      <Dropdown
                        customCls={"dropdown--grad"}
                        list={masterData?.courses}
                        value={masterData?.courses[0]}
                        handleClick={() => {}}
                      />
                      <Dropdown
                        customCls={"dropdown--grad"}
                        list={masterData?.language}
                        value={masterData?.language[0]}
                        handleClick={() => {}}
                      />
                      <Dropdown
                        customCls={"dropdown--grad"}
                        list={sortArr}
                        value={sortArr[0]}
                        handleClick={() => {}}
                      />
                    </div>
                    <div className="filters__grid-rhs">
                      <div className="filters__search">
                        <InputSearchBox
                          searchConfig={{ parentExtraCls: "input--searchBox" }}
                          srchPlaceholder={"Search"}
                          srchID={"filter_srch" + index}
                          handleInput={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="course__wrap">
                <div className="main__container">
                  <div className="onlineCourses__grid">
                    {!popularCourses?.length && <>No Data Found</>}
                    {popularCourses?.slice(0, maxItems).map((item) => (
                      <CourseCard
                        id={item?.prod_id}
                        key={item?.id}
                        thumbnail={item.thumbnail}
                        title={item.title}
                        catergory={homeState?.activeCourseType}
                        rating={item.rating}
                        proposerThumb={item?.professor?.image}
                        proposerName={item?.professor?.name}
                        publishDate={item?.start_date}
                        enrolledStudentThumb={item.enrolled_students?.images}
                        enrolledCount={item?.count}
                        courseDiscountPrice={item?.discounted_price}
                        coursePrice={item?.actual_price}
                      />
                    ))}
                  </div>
                </div>
                {maxItems < popularCourses?.length && (
                  <div className="main__container">
                    <div className="onlineCourses__cta">
                      <CtaButton
                        btnCls={"btn btn__primary"}
                        iconCls={"edi-loadmore"}
                        btnText={"Load More"}
                        onClickProp={loadMore}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      {isFilterOverlayOpen && (
        <FilterOverlay handleApply={toggleFilterOverlay} />
      )}
    </>
  );
};

export default OnlineCourses;
