import React from "react";
import Anchor from "../anchor";
import { CtaButton } from "../ctaButton";
import { useDispatch, useSelector } from "react-redux";
import { home } from "../../redux/actions";

const BranchCard = ({
  branchName,
  branchAddress,
  branchNumber,
  mapLink,
  toggleBranchOverlay = () => {},
}) => {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state?.app?.home);

  return (
    <>
      <div className="branchCard" data-aos="zoom-in">
        <div className="branchCard-head">
          <h2 className="branchCard-title">{branchName}</h2>
          <div className="branchCard-address">
            <i className="edi-location"></i>
            <address>{branchAddress}</address>
          </div>
        </div>
        <div className="branchCard-cta">
          <Anchor
            linkCls={"btn btn__link btn__link--primary"}
            linkText={"Get Direction"}
            hyperLink={mapLink}
          />
        </div>

        <div className="branchCard-body">
          <Anchor
            linkCls={"btn btn__tertiary btn__tertiary--outline"}
            iconCls={"edi-phone"}
            linkText={"Call Now"}
            hyperLink={`tel:${branchNumber}`}
          />
          <CtaButton
            btnCls={"btn btn__tertiary"}
            iconCls={"edi-edit"}
            btnText={"Enquire Now"}
            onClickProp={() => {
              toggleBranchOverlay();
              dispatch(
                home.setShowEnrollOverlay({
                  branch: branchName,
                  course: homeState?.activeCourseType,
                })
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default BranchCard;
